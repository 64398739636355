.boardArea {
  width: 100%;
  height: 100%;
  background: #000000cc;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scoreboard {
  position: fixed;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 100%;
  // height: 100%;
  // border-radius: .5rem;
  width: 22em;
  // background: #000000cc;
  // user-select: none;
  // font-family: 'Patrick Hand', 'Pretendard';
  // font-size: 1.5em;

  ul {
    width: 100%;
    padding: auto;

    li {
      font-size: 1em;

      .number-badge {
        margin-right: 0.5rem;
        display: inline-block;
        min-width: 2em;
      }
    }
  }
}

p {
  span {
    margin-right: 0.2em;
  }
}

.margin-right-p {
  margin-right: 0.2em;
}

.my-rank-badge {
  margin-right: 0.5rem;
  display: inline-block;
  min-width: 2em;
  // border-top-width: 2px
}

.my-rank-name {
  display: block;
  margin-top: 0.2em;
  margin-left: 0.1em;
}

.my-rank-list {
  // --bs-list-group-bg: var(--bs-list-group-border-color);
  --bs-list-group-bg: #e3e3e3;
}

.gold-bg {
  background-color: #ffd541;
  --bs-bg-opacity: 1;
}

.silver-bg {
  background-color: #c9bcbd;
  --bs-bg-opacity: 1;
}

.bronz-bg {
  background-color: #985336;
  --bs-bg-opacity: 1;
}

.rank {
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
}

.my-rank {
  color: #ff5722;
  /* 나의 순위 색상 */
}
