.headerArea {
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.2em;
  user-select: none;

  .bestScoreArea {
    flex: 1;
    display: flex;
    height: 3em;
    flex-direction: column;
    color: #fff;

    .text {
      font-family: 'Patrick Hand', 'Pretendard', serif;
      font-size: 0.8em;
      font-weight: 700;
    }

    .number {
      font-family: 'Pacifico', 'Pretendard', serif;
      font-size: 2em;
      line-height: .5em;
      margin-top: 0.1em;
      letter-spacing: -2px;
    }
  }

  .scoreArea {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .score {
      font-family: 'Pacifico', 'Pretendard', serif;
      font-size: 3.5em;
      line-height: 1.3em;
      color: #fff;
    }
  }

.itemArea {
  flex: 0.6;
  display: flex;
  height: 3em;
  flex-direction: column;
  text-align: right;

  .text {
    font-family: 'Patrick Hand', 'Pretendard', serif;
    font-size: 0.8em;
    font-weight: 700;
    color: #fff;
    user-select: none;
  }

  .next {
    width: 100%;
    height: 2em;
    padding: .2em 0;

    .itemBtn {
      position: relative !important;
      margin-top: 0.2em;

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(1.2);
      }

      .badge {
        font-size: 0.6em;
      }

      .img {
        display: flex;
        height: 2.2em;
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
  .nextArea {
    flex: 0.4;
    display: flex;
    height: 3em;
    flex-direction: column;
    text-align: right;

    .text {
      font-family: 'Patrick Hand', 'Pretendard', serif;
      font-size: 0.8em;
      font-weight: 700;
      color: #fff;
      user-select: none;
    }

    .next {
      width: 100%;
      height: 2em;
      padding: .2em 0;

      .img {
        display: flex;
        height: 1.8em;
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.zoom-in-out {
  animation: zoom-in-out 2s;
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.shake {
  animation: shake 0.2s ease-in-out 5;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-0.3em);
  }

  50% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(0.3em);
  }

  100% {
    transform: translateX(0);
  }
}
